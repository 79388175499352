.home-container {
  margin-top: -100px;
  background-color: black;
  background-image: url('./homepageArt2.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 101vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.home-container .left-home-section {
  width: 40%;
  padding-left: 10rem;
}

.home-container .title>h1 {
  color: rgb(255, 255, 255);
  font-size: 52px;
  padding-bottom: 20px;
}

.home-container .caption{
  color: rgb(181, 181, 181);
}

.home-container .home-play {
    margin-top: 32px;
}

.home-container .home-btns .btn {
    margin: 7px;
} 

.home-container .right-home-section {
  width: 0%;
}


/* @media screen and (max-width: 1024px) {
  img.background {
      left: 50%;
      margin-left: -512px;
  }
} */

/* .fa-play-circle {
margin-left: 4px;
} */

/* @media screen and (max-width: 960px) {
.hero-container > h1 {
  font-size: 70px;
  margin-top: -150px;
}
} */

/* @media screen and (max-width: 768px) {
.hero-container > h1 {
  font-size: 50px;
  margin-top: -100px;
}

.hero-container > p {
  font-size: 30px;
}

.btn-mobile {
  display: block;
  text-decoration: none;
}

.btn {
  width: 100%;
}
} */