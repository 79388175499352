.about-container {
    margin-top: 6%;
    width: 20%;
    border-radius: 10px;
    border-width: 0px;
}

.student-box-container {
    display: flex;
    flex-direction: row;
}

.student-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0rem 3rem 0rem 3rem;
    
}

.student {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* margin: 0rem 3rem 2rem 3rem; */
}

.forgot-password-link{
    color: black;
    text-decoration: none;
}

.register-link{
    color: #ff006f;
    text-decoration: none;
}