#marbles-full-container {
    display: flex;
    flex-direction: row;
}

#marbles-full-container #background {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

#marbles-full-container #empty-div {
    width: 50%;
}

#marbles-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#marbles-container #header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #ffffe0;
}

#marbles-container #score {
    display: none;
}

#marbles-container #pregame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#marbles-container #pregame #settings {
    margin-bottom: 10px;
}

#marbles-container #game {
    display: none;
    flex-direction: column;
    align-items: center;
}

#marbles-container #game #answer {
    display: flex;
    margin-top: 1em;
}

#marbles-container #postgame {
    display: none;
    flex-direction: column;
    align-items: center;
}

#marbles-container #question-text {
    font-weight: bold;
    color: #de8c93;
}

#marbles-container .gray {
    fill: gray;
}

#marbles-container .red {
    fill: red;
}

#marbles-container .green {
    fill: green;
}

#marbles-container #win-text {
    display: none;
    font-weight: bold;
    color: green;
}

#marbles-container #lose-text {
    display: none;
    font-weight: bold;
    color: red;
}

#marbles-container #error {
    flex-direction: column;
    align-items: center;
}


#marbles-container #error-message {
    font-weight: bold;
    color: red;
}

#marbles-container #correct {
    flex-direction: column;
    align-items: center;
}

#marbles-container #correct-message {
    font-weight: bold;
    color: green;
}

#marbles-container #game input[type='radio'] {
    height: 25px;
    width: 25px;
    vertical-align: middle;
}

#marbles-container #game label { font-size: 22px } 

#marbles-container #tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
}