#sugar-honeycombs-full-container {
    /* position: relative; */
    display: flex;
    flex-direction: row;
}

#sugar-honeycombs-full-container #background {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

#sugar-honeycombs-full-container #empty-div {
    width: 55%;
}


#sugar-honeycombs-full-container #sound {
    cursor: pointer;
    position: absolute;
    top: 100px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    z-index: 10; /* Ensure this is higher than other elements */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
}
#sugar-honeycombs-full-container #mute {
    cursor: pointer;
    position: absolute;
    top: 150px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    z-index: 10; /* Ensure this is higher than other elements */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
}


/* define a CSS class that represents the clicked state of the sound/mute icon */
.clicked {
    transform: scale(0.9); /* Slightly smaller */
    opacity: 0.7; /* Slightly transparent */
    /* Other styles for clicked state */
}


#sugar-honeycombs-full-container #back {
    cursor: pointer;
    position: absolute;
    top: 200px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    z-index: 10; /* Ensure this is higher than other elements */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-50px); }
    50% { transform: translateX(50px); }
    75% { transform: translateX(-50px); }
    100% { transform: translateX(50px); }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
/* Create CSS Classes for Each Animation: */
.shakeAnimation {
    animation: shake 3.5s ease-in-out infinite;
}

.bounceAnimation {
    animation: bounce 2s ease-in-out infinite;
}

#sugar-honeycombs-full-container #overlay-image {
    position: absolute;
    bottom: 100px; /* place it at the bottom of the container */
    left: 200px; /* place it at the left of the container */
    z-index: 1; /* ensure it's above the background image */
    opacity: 0.9; /* adjust transparency as needed */
    width: 450px; /* set the exact width */
    height: 500px; /* set the exact height */
    
}


#sugar-honeycombs-full-container #gameover-overlay {
    position: absolute;
    z-index: 1;
    display: flexs; /* Initially hidden, will be displayed with JavaScript */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 25vh; /* Center it vertically */
    left: 20%; /* Center it horizontally */
}



#sugar-honeycombs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sugar-honeycombs-container #header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #ffffe0;
}



#sugar-honeycombs-container #pregame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sugar-honeycombs-container #pregame #settings {
    margin-bottom: 10px;
}

#sugar-honeycombs-container #game {
    display: none;
    flex-direction: column;
    align-items: center;
}

#sugar-honeycombs-container #game input[type='radio'] {
    height: 25px;
    width: 25px;
    vertical-align: middle;
}

#sugar-honeycombs-container #game #answer {
    display: flex;
}



#sugar-honeycombs-container #game #answer input {
    margin-right: 1rem;
    width: 16rem;
    margin-bottom: 10px;
}

#sugar-honeycombs-container #game #choices div {
    margin-bottom: 20px;
    display: block;
    align-items: center;
}


#sugar-honeycombs-container #game #choices input[type="radio"] {
    margin-right: 10px;
}

#sugar-honeycombs-container #postgame {
    display: none;
    flex-direction: column;
    align-items: center;
}

#sugar-honeycombs-container #question-text {
    font-weight: bold;
    color: #de8c93;
}

#sugar-honeycombs-container #win-text {
    display: none;
    font-weight: bold;
    color: green;
}

#sugar-honeycombs-container #lose-text {
    display: none;
    font-weight: bold;
    color: red;
}

#sugar-honeycombs-container #error {
    flex-direction: column;
    align-items: center;
}


#sugar-honeycombs-container #error-message {
    font-weight: bold;
    color: red;
}

#sugar-honeycombs-container #initialization_msg {
    font-weight: bold;
    font-size: 40px;
    color: red;
}


#sugar-honeycombs-container #correct {
    flex-direction: column;
    align-items: center;
}

#sugar-honeycombs-container #correct-message {
    font-weight: bold;
    color: green;
}



#sugar-honeycombs-container #audio-btn {
    background-color: #d8b863;
}


#sugar-honeycombs-container #tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
}

#sugar-honeycombs-container .red {
    fill: red;
}

#sugar-honeycombs-container #timeout-container {
    width: 100%;
    height: 30px;
    background-color: #ddd; /* background of the container */
}

#sugar-honeycombs-container #timeout-bar { /* Important: #sugar-honeycombs-container is required if you put timeout-bar within the container */
    width: 100%; /* initial full width */
    height: 100%;
    background-color: #4CAF50; /* color of the timeout bar */
}

