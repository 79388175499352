#tug-of-war-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tug-of-war-container img.background {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

#tug-of-war-container #header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    background-color: aliceblue;
}

@media screen and (max-width: 1024px) {
    #tug-of-war-container img.background {
        left: 50%;
        margin-left: -512px;
    }
}

#tug-of-war-container #score {
    display: none;
}

#tug-of-war-container #pregame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tug-of-war-container #pregame #settings {
    margin-bottom: 10px;
}

#tug-of-war-container #game {
    display: none;
    flex-direction: column;
    align-items: center;
}

#tug-of-war-container #game #answer {
    display: flex;
}

#tug-of-war-container #game #answer input {
    margin-right: 1rem;
    width: 16rem;
}

#tug-of-war-container #postgame {
    display: none;
    flex-direction: column;
    align-items: center;
}

#tug-of-war-container #question-text {
    font-weight: bold;
    color: #de8c93;
}

#tug-of-war-container .gray {
    fill: gray;
}

#tug-of-war-container .red {
    fill: red;
}

#tug-of-war-container .green {
    fill: green;
}

#tug-of-war-container #win-text {
    display: none;
    font-weight: bold;
    color: green;
}

#tug-of-war-container #lose-text {
    display: none;
    font-weight: bold;
    color: red;
}

#tug-of-war-container #error {
    flex-direction: column;
    align-items: center;
}

#tug-of-war-container #error-message {
    font-weight: bold;
    color: red;
}

#tug-of-war-container #correct {
    flex-direction: column;
    align-items: center;
}

#tug-of-war-container #correct-message {
    font-weight: bold;
    color: green;
}