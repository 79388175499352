:root {
    -primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn-primary {
    background-color: var(-primary);
    color: rgb(0, 0, 0);
    border: none;
}

.btn-back {
    margin: 20px 0 0 20em;
    background-color: teal;
    color: whitesmoke;
    border: none;
}