li {
    list-style-type: none;
}

.navbar {
    background: none;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-size: 1.2rem;
    position: sticky;
    top: 25px;
    z-index: 999;
}

.logo {
    margin-left: 30px;
    margin-right: 10px;
    margin-bottom: 30px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.nav-item {
    display: inline;
    padding-left: 30px;

}

.nav-item>a {
    color: white;
    text-decoration: none;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

}

.nav-item>a:hover {
    text-decoration: underline;
}

/* .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0px;
    max-width: 1500px;
} */

/* .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}



.nav-menu {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: start;
    margin-right: 2rem;
}

.nav-item {
    height: 60px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0rem 1rem;
    height: 100%;
} */

/* .fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
} */